<template>
  <div class="page-center">
    <div class="misc-wrapper ">
      <div
        v-if="!isAuthenticated"
        class="brand-logo"
      >
        <e8-logo class="no-select" />
      </div>

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            Page Not Found
          </h2>
          <p class="mb-2">
            Oops! 😖 The requested URL was not found on this server.
          </p>

          <b-button
            class="mb-2 btn-sm-block bg-primary text-header  font-size-20"
            :to="{path:'/'}"
          >
            Back to home
          </b-button>
        </div>
      </div>
    </div></div>
</template>

<script>
/* eslint-disable global-require */
import { BButton } from 'bootstrap-vue'
import E8Logo from '@/components/Logo.vue'

export default {
  components: {
    E8Logo,
    BButton,
  },
  data() {
    return {
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
  },
}
</script>

<style lang="scss">
.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  .misc-inner {
    position: relative;
    max-width: 750px;
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin: 0;
    .brand-text {
      font-weight: 600;
    }
    svg {
      height: 28px;
    }
  }
}
</style>
